import React from 'react';

const Tile = ({ layers, zLayer, onClick, isSelected, freeMove, gameEnded, delayZ }) => {
  return (
    <div 
      role="gridcell" 
      className={`tile-container ${isSelected ? 'selected' : ''} ${ freeMove ? 'free-move' : '' }`} 
      onClick={onClick}
    >
      <div className="tile-selector">
        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect className="selector-dashed-outline" x="3" y="3" width="94" height="94" stroke="#665c54" strokeWidth="6" strokeDasharray="5 3"></rect>
          <rect className="selector-solid-outline" x="3" y="3" width="94" height="94" stroke="#665c54" strokeWidth="6"></rect>
        </svg>
      </div>
      <div className="message-container">
        <div className="tile-message"></div>
      </div>
      <div className="tile-frame">
        {layers.map((layerObj, index) => (
          <svg key={index} className={`tile-layer layer-${index} ${layerObj.matched ? 'matched' : ''}`} style={{ transitionDelay: `${0.05 * index + 0.05}s` }}>
            <use xlinkHref={`#${layerObj.layer}`}></use>
          </svg>
        ))}
        {zLayer && (
          <svg className={`tile-layer layer-z ${gameEnded ? 'matched' : ''}`} style={{ animationDelay: `${delayZ}s` }}>
            <use xlinkHref={`#${zLayer}`}></use>
          </svg>
        )}
      </div>
    </div>
  );
};

export default Tile;
