import logo from './logo.svg';

import { Helmet } from 'react-helmet'; // Add this import

import React, { useState, useEffect } from 'react';


import Board from './Board'


const urlParams = new URLSearchParams(window.location.search);
let idFromUrl = false;
function App() {

  const getGameId = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const gameIdFromUrl = urlParams.get('gameId');

    if (gameIdFromUrl) {
      // If gameId is provided in the URL, use that
      idFromUrl = true
      return parseInt(gameIdFromUrl);
    } else {
      // Calculate gameId based on the current date
      const fixedDate = new Date('2023-12-03');

      // Current date
      const currentDate = new Date();

      // Calculate the difference in days
      const timeDifference = currentDate.getTime() - fixedDate.getTime();
      const daysDifference = Math.ceil(timeDifference / (1000 * 3600 * 24));

      // GameId will be the difference in days (plus 1 to start from gameId 1)
      return daysDifference + 1;
    }
  };

  const [currentCombo, setCurrentCombo] = useState(0);
  const [longestCombo, setLongestCombo] = useState(0);
  const [gameId, setGameId] = useState(getGameId());

  const handleCombo = (num) => {
    if (num === 0) {
      setCurrentCombo(0);
    } else {
      setCurrentCombo(prevState => parseInt(prevState) + 1);
    }
  };

  useEffect(() => {
    if (currentCombo > longestCombo) {
      setLongestCombo(currentCombo);
    }
  }, [currentCombo]);

  useEffect(() => {
    window.freestar.queue.push(function() {
      window.freestar.newAdSlots({
        placementName: 'tilesgame_below_buttons',
        slotId: 'ad-ingame'
      });
    });
  }, []);

  const startNewGame = () => {
    const randomIndex = Math.floor(Math.random() * 500);

    // Redirect to new page with gameId as URL parameter
    window.location.href = window.location.origin + '?gameId=' + randomIndex;
  };

  useEffect(() => {

  }, [gameId])

  const restartGame = () => {
    window.location.reload();
  };

  return (

    <>
    {idFromUrl && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
        
    <div className="game-container">
      <div class="board-wrapper">
          <Board gameId={ gameId } comboChange={handleCombo} />
        <div class="panel-wrapper">
          <div class="combo-wrapper">
            <div class="combo-div">Current Combo: { currentCombo }</div>
            <div class="combo-div">Longest Combo: { longestCombo }</div>
          </div>
          <div class="btn-wrapper">
            <button class="game-btn submit-btn" onClick={startNewGame}>New Game</button>
            <button class="game-btn" onClick={restartGame}>Restart</button>
          </div>
          <div id="ad-ingame" class="ad-ingame">

          </div>
        </div>
      </div>
    </div>

    </>
  );
}

export default App;
