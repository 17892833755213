import React, { useState, useEffect } from 'react';
import Tile from './Tile';
import data_svgs from './data';
import seedrandom from 'seedrandom';

const Board = ({ gameId, comboChange }) => {
  const [tiles, setTiles] = useState([]);
  const [selected, setSelected] = useState(null);
  const [prevSelected, setPrevSelected] = useState(null);
  const [freeMove, setFreeMove] = useState(null)
  const [gameEnded, setGameEnded] = useState(false)

  const handleTileClick = (rowIndex, colIndex) => {
    if( gameEnded )
        return;
    // Directly update the selected tile
    setPrevSelected(selected);
    setSelected({ rowIndex, colIndex });
  };

  // Add a function to handle tile selection
  useEffect(() => {
    if (!selected || !prevSelected) return;

    if( selected.rowIndex == prevSelected.rowIndex && selected.colIndex == prevSelected.colIndex )
        return;
  
    // Extract layer names and matched status from the current and previous selected tiles
    const currentTileLayers = tiles[selected.rowIndex][selected.colIndex];
    const prevTileLayers = tiles[prevSelected.rowIndex][prevSelected.colIndex];
  
    // Filter out layers that are already matched
    const currentUnmatchedLayers = currentTileLayers.filter(layer => !layer.matched).map(layer => layer.layer);
    const prevUnmatchedLayers = prevTileLayers.filter(layer => !layer.matched).map(layer => layer.layer);
    
    if( prevUnmatchedLayers.length == 0 )
        return;

    // Find common layers among unmatched layers
    const commonLayers = currentUnmatchedLayers.filter(layer => prevUnmatchedLayers.includes(layer));
  
    if (commonLayers.length === 0) {
      comboChange(0);
      setSelected(null);
      setPrevSelected(null);
    } else {
      // Update tiles with matched property for common layers
      setTiles(tiles => {
        const updatedTiles = tiles.map((row, rIndex) => 
          row.map((tile, cIndex) => {
            if ((rIndex === selected.rowIndex && cIndex === selected.colIndex) || (rIndex === prevSelected.rowIndex && cIndex === prevSelected.colIndex)) {
              return tile.map(layerObj => 
                commonLayers.includes(layerObj.layer) ? { ...layerObj, matched: true } : layerObj
              );
            }
            return tile;
          })
        );
    
        // Check if all layers in all tiles are matched
        const allMatched = updatedTiles.every(row => row.every(tile => tile.every(layerObj => layerObj.matched)));
    
        if (allMatched) {
          setGameEnded(true); // Set the game as ended
        }
    
        return updatedTiles;
      });
      comboChange(1);
    }

    
  
  }, [selected, prevSelected]);
  
  
  
  useEffect(() => {
    if (selected) {
      const selectedTile = tiles[selected.rowIndex][selected.colIndex];
      
      // Check if all layers in the selected tile are matched
      const allLayersMatched = selectedTile.every(layerObj => layerObj.matched);
  
      if (allLayersMatched) {
        setFreeMove(selected);
      }
    }
  }, [selected, tiles]);
  
  


  useEffect(() => {
    const rng = seedrandom(gameId);
    const getRandomInt = (min, max) => {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(rng() * (max - min + 1)) + min;
    };

    const shuffleArray = array => {
        for (let i = array.length - 1; i > 0; i--) {
          const j = getRandomInt(0, i);
          [array[i], array[j]] = [array[j], array[i]];
        }
      };
  
    const maxId = Math.max(...data_svgs.map(data => data.id));
    const adjustedGameId = (gameId - 1) % maxId + 1;
    const symbols = data_svgs.find(data => data.id === adjustedGameId).symbols;
  
    // Initialize layer pools with the required distribution
    const layerPools = { A: [], B: [], C: [] };
    Object.keys(symbols).forEach(symbol => {
      if (symbol !== 'Z') {
        for (let i = 1; i <= symbols[symbol]; i++) {
          // Each layer is initially used twice
          layerPools[symbol].push(`${symbol}${i}`, `${symbol}${i}`);
        }
        // Add extra instances to reach 4 usages for some layers to complete the tile count
        while (layerPools[symbol].length < 30) {
          let extraLayerIndex = getRandomInt(1, symbols[symbol]);
          layerPools[symbol].push(`${symbol}${extraLayerIndex}`, `${symbol}${extraLayerIndex}`);
        }
      }
    });
  
    // Shuffle each pool
    Object.values(layerPools).forEach(shuffleArray);
  
    // Initialize empty tiles
    const gridRows = 6;
    const gridCols = 5;
    let randomTilesArray = Array.from({ length: gridRows }, () => 
      Array.from({ length: gridCols }, () => [])
    );
  
    // Assign layers to tiles
    for (let row = 0; row < gridRows; row++) {
      for (let col = 0; col < gridCols; col++) {
        ['A', 'B', 'C'].forEach(symbol => {
          let layer = layerPools[symbol].pop();
          randomTilesArray[row][col].push({ layer, matched: false });
        });
      }
    }
  
    setTiles(randomTilesArray);
  }, [gameId]);
  
  
  
  
  
  
  

  // Find the game data SVG string based on gameId
    const maxId = Math.max(...data_svgs.map(data => data.id));

    // Calculate adjustedGameId based on the maximum ID
    const adjustedGameId = (gameId - 1) % maxId + 1;
    const gameData = data_svgs.find(data => data.id === parseInt(adjustedGameId) );

  // If gameData or tiles is not loaded yet, return null or loading state
  if (!gameData || tiles.length === 0) {
    return <div>Loading...</div>;
  }

  console.log(tiles)

  const getZLayer = (rowIndex, colIndex) => {
    // Implement logic to determine which Z layer to use
    // Example: alternating pattern
    return `Z${(rowIndex + colIndex) % 3 + 1}`; // This will cycle through Z1, Z2, Z3
  };

  return (
    <div class="board-wrapper-inner">
        <div className="svg-hidden-list" dangerouslySetInnerHTML={{ __html: gameData.svg }}></div>
        
        <div className="board">
        {tiles.map((row, rowIndex) => (
            row.map((tileLayers, colIndex) => (
            <div 
                key={`${rowIndex}-${colIndex}`} 
                className={`cell ${((rowIndex + colIndex) % 2 === 0) ? 'cell-color1' : 'cell-color2'}`}
            >
                <Tile 
                layers={tileLayers}
                zLayer={getZLayer(rowIndex, colIndex)}
                onClick={() => handleTileClick(rowIndex, colIndex)}
                isSelected={selected?.rowIndex === rowIndex && selected?.colIndex === colIndex}
                freeMove={freeMove?.rowIndex === rowIndex && freeMove?.colIndex === colIndex}
                gameEnded={ gameEnded }
                delayZ={ (rowIndex + 1) * (colIndex + 1) * 0.1 + 1  }
                />
            </div>
            ))
        ))}
        </div>

        {gameEnded && (
        <div className="game-over">
            Congratulations! All layers matched.
        </div>
        )}
    </div>
  );
};

export default Board;
